
button, button::before{
    width: 300px;
    height: 69px;
}
button{
    background-color: transparent;
    border: 2px solid rgba(0, 0, 0, 0);
    color: #444444;
}
button::before{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    top:-10px;
    font-size: 1.3rem;
    font-weight:bold;
    z-index: -1;
    transition: 0.2s;
}
button:hover{
    cursor: pointer;
}
.greenButton{
    border-color: #C2F587;
}
.pinkButton{
    border-color: #F66F92;
}
.purpleButton{
    border-color: #A958FF;
}

.greenButton::before{
    background-color: #DFFFBA;
}
.pinkButton::before{
    background-color: #F5CEC8;
}
.purpleButton::before{
   background-color: #D6B0FF;
}
.greenButton:hover{
    border:none;
}
.pinkButton:hover{
    border:none;
}
.purpleButton:hover{
    border:none;
}
.greenButton:hover::before{
    box-shadow: 6px -6px 0px #C2F587;
}
.pinkButton:hover::before{
    box-shadow: 6px -6px 0px #F66F92;
}
.purpleButton:hover::before{ 
    box-shadow: 6px -6px 0px #A958FF;
}






@media (max-width: 992px)  {
    button, button::before{
        width: 415px;
        height: 90px;      
        font-size: 1.5rem;
    }
    .shadow{
        margin: 1.5rem;
    }
    
}
@media (max-width: 767px) {
    button, button::before{
        width: 215px;
        height: 50px;      
        font-size: 1rem;
    }
    .shadow{
        margin: 1rem;
    }
    
}