.container{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

/* Principals buttons*/
.btn_container{    
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-around;
}


@media (max-width: 992px)  {
    .btn_container{    
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    
}
@media (max-width: 767px) {
   
    
}