.container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.profile_img{
    width: 100px;
    border-radius: 50%;
}
.text{
    font-weight: bold;
    text-align: center;
    font-size: 1.5rem;
    color: #444444;
    margin: 0.6rem;
}

@media (max-width: 767px) {
    /* width: 100px; */
    .profile_img{
        width: 55px;
    }
    .text{
        font-size: 1rem;
    }

}