/*ISO*/
.iso{
    width: 60px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    filter: invert(25%) sepia(18%) saturate(20%) hue-rotate(27deg) brightness(92%) contrast(89%);
}
.text{
    text-align: center;
    font-weight: bold;
    color: #444444;
    font-size: 0.8rem;
}

@media (max-width: 992px)  {
    .iso{
        width: 130px;
    }
    .text{
        font-size: 1rem;
    }
}
@media (max-width: 767px) {
    .iso{
        width: 82px;
    }
    
}