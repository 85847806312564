.img_container{
display: flex;
justify-content: space-around;
width: 20%;
}

.img_container>img{
    width: 35px;
    filter: invert(25%) sepia(18%) saturate(20%) hue-rotate(27deg) brightness(92%) contrast(89%);
}


@media (max-width: 992px)  {
    .img_container{
        width: 30%;
    }
    
    .img_container>img{
        width: 40px;
    }
}
@media (max-width: 767px) {
    .img_container{
        width: 40%;
    }
    .img_container>img{
        width: 25px;
    }
}